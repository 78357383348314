import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Image,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  AbsoluteCenter,
  Hide,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import { MobileNav } from '../layout/MobileNav';
import { SidebarContent } from '../layout/SidebarContent';
import { IDowntime, IModule, IStep, LogAction, StepState, StepType } from '../types';
import { getAllModules } from '../API/module';
import { StepTile } from './StepTile';
import { openChest } from '../API/step';
import chest_open_gif from '../resources/step/chest_open.gif';
import target_img from '../resources/target.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Logger } from '../Logger';
import { TabContext } from '../TabContext';
import {Image as ChakraImage} from '@chakra-ui/react';
import start_sound from '../resources/sounds/start_lesson.mp3';
import chest_sound from '../resources/sounds/chest_open.mp3';

// Characters
import geraldine_1 from '../resources/characters/geraldine_1.svg';
import geraldine_2 from '../resources/characters/geraldine_2.svg';
import geraldine_3 from '../resources/characters/geraldine_3.svg';
import alyssia_1 from '../resources/characters/alyssia_1.svg';
import alyssia_2 from '../resources/characters/alyssia_2.svg';
import alyssia_3 from '../resources/characters/alyssia_3.svg';
import estelle_1 from '../resources/characters/estelle_1.svg';
import estelle_2 from '../resources/characters/estelle_2.svg';
import estelle_3 from '../resources/characters/estelle_3.svg';
import lev_1 from '../resources/characters/lev_1.svg';
import lev_2 from '../resources/characters/lev_2.svg';
import lev_3 from '../resources/characters/lev_3.svg';
import benoit_1 from '../resources/characters/benoit_1.svg';
import benoit_2 from '../resources/characters/benoit_2.svg';
import benoit_3 from '../resources/characters/benoit_3.svg';
import group_pic from '../resources/characters/group.svg';
import Cookies from 'js-cookie';
import { getDowntime } from '../API/userData';
import { useUser } from '../UserContext';

interface CharacterProps{
  name: string;
  action:number;
  [key: string]: any;
}
const Character: React.FC<CharacterProps> = ({ name, action, ...imageProps }) => {
  const [imageSrc, setImageSrc] = useState<string>(geraldine_1);

  useEffect(() => {
    switch (name) {
      case "Geraldine":
        switch (action) {
          case 1:
            setImageSrc(geraldine_1);
            break;
          case 2:
            setImageSrc(geraldine_2);
            break;
          case 3:
            setImageSrc(geraldine_3);
            break;
          default:
            setImageSrc('');
            break;
        }
        break;
      case "Alyssia":
        switch (action) {
          case 1:
            setImageSrc(alyssia_1);
            break;
          case 2:
            setImageSrc(alyssia_2);
            break;
          case 3:
            setImageSrc(alyssia_3);
            break;
          default:
            setImageSrc('');
            break;
        }
        break;
        case "Estelle":
          switch (action) {
            case 1:
              setImageSrc(estelle_1);
              break;
            case 2:
              setImageSrc(estelle_2);
              break;
            case 3:
              setImageSrc(estelle_3);
              break;
            default:
              setImageSrc('');
              break;
          }
          break;
        case "Lev":
          switch (action) {
            case 1:
              setImageSrc(lev_1);
              break;
            case 2:
              setImageSrc(lev_2);
              break;
            case 3:
              setImageSrc(lev_3);
              break;
            default:
              setImageSrc('');
              break;
          }
          break;
        case "Benoit":
          switch (action) {
            case 1:
              setImageSrc(benoit_1);
              break;
            case 2:
              setImageSrc(benoit_2);
              break;
            case 3:
              setImageSrc(benoit_3);
              break;
            default:
              setImageSrc('');
              break;
          }
          break;
          case "Group":
            setImageSrc(group_pic);
          break;
      default:
        setImageSrc('');
        break
    }
  }, []);

  return <ChakraImage 
    draggable={false} 
    src={imageSrc} 
    height={"250px"}
    {...imageProps}
    />
}

const ChestModal = ({ isOpen, onClose, step }: { isOpen: boolean, onClose: () => void, step: IStep|null }) => {
  return <Modal isOpen={isOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    {/* <ModalHeader><Text fontWeight={800}>Coffre ouvert</Text></ModalHeader> */}
    {/* <ModalCloseButton /> */}
    <ModalBody mt={5}>
      <Center>
        <Text fontSize={"2xl"} color={'#1CB0F6'} fontWeight={800}>Tu as gagné <span style={{fontWeight:900}}>{step?.reward}</span> cristaux !</Text>
      </Center>
      <Center mt={1}>
        <Text fontSize={"md"} fontWeight={600}>Ne les dépense pas tous à la fois... <i className="em-svg em-face_with_hand_over_mouth" aria-label="SMILING FACE WITH SMILING EYES AND HAND COVERING MOUTH"></i></Text>
      </Center>
      <Center>
        <Image draggable={false} width={"250px"} src={chest_open_gif} alt={"Coffre ouvert"} />
      </Center>
    </ModalBody>

    <ModalFooter>
      {/* <Button size={"lg"} fontWeight={700} colorScheme='blue' mr={3} onClick={onClose}>
        Super !
      </Button> */}
      <Box
        onClick={onClose}
        cursor={'pointer'}
        opacity={'1'}
        border={'2pt solid #1CB0F6'} 
        p={3}
        _hover={{ opacity: '0.8' }}
        bgColor={'#1CB0F6'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#ffffff'}>
            Super !
      </Box>
    </ModalFooter>
  </ModalContent>
</Modal>
}

const StepStartModal = ({ isOpen, onClose, onStart }: { isOpen: boolean, onClose: () => void, onStart: () => void }) => {
  return <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    {/* <ModalHeader><Text fontWeight={800}>Coffre ouvert</Text></ModalHeader> */}
    {/* <ModalCloseButton /> */}
    <ModalBody mt={5}>
      <Center>
        <Text mb={5} fontSize={"2xl"} color={'#1CB0F6'} fontWeight={900}>Commencer l'étape ?</Text>
      </Center>
      <Center mt={1} mb={5}>
        <Text textAlign={'justify'} fontSize={"md"} fontWeight={600}>En commençant l'étape, un chrono de 10 minutes se lancera. Si le chrono se termine avant que tu aies terminé l'étape, les diamants de cette étape seront perdus.</Text>
      </Center>
      <Center>
        {/* <Image draggable={false} width={"250px"} src={chest_open_gif} alt={"Coffre ouvert"} /> */}
      </Center>
    </ModalBody>

    <ModalFooter>
      <Box
        onClick={onClose}
        cursor={'pointer'}
        opacity={'1'}
        border={'2pt solid #EF5555'} 
        p={3}
        _hover={{ opacity: '0.8' }}
        bgColor={'#EF5555'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#ffffff'}>
            En fait, non
      </Box>
      <Spacer />
      <Box
        onClick={onStart}
        cursor={'pointer'}
        opacity={'1'}
        border={'2pt solid #1CB0F6'} 
        p={3}
        _hover={{ opacity: '0.8' }}
        bgColor={'#1CB0F6'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#ffffff'}>
            C'est parti !
      </Box>
    </ModalFooter>
  </ModalContent>
</Modal>
}

const ProgressTracker: React.FunctionComponent = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modules, setModules] = useState<IModule[]>([]);
    const [noModules, setNoModules] = useState<boolean>(false);
    const [happyHourModule, setHappyHourModule] = useState<IModule|null>(null);
    const [remainingHappyHoursDays, setRemainingHappyHoursDays] = useState<number>(0);
    const [totalHappyHoursDays, setTotalHappyHoursDays] = useState<number>(0);
    const [noHappyHour, setNoHappyHour] = useState<boolean>(false);
    const [listenerAdded, setListenerAdded] = useState(false);
    const [viewportModule, setViewportModule] = useState<IModule|null>(null);
    const [currentChest, setCurrentChest] = useState<IStep|null>(null);
    const chestDisclosure = useDisclosure();
    const MODULES_COLORS = ["#1cb0f6", "#CE82FF", "#04CD9C", "#FF9601", "#FF86D0", "#FF4B4B", "#CC348D", "#DC8F47"];
    const [currentModuleColor, setCurrentModuleColor] = useState(MODULES_COLORS[0]);
    const navigate = useNavigate();
    const currentTab = useContext(TabContext).currentTab;
    const stepStartDisclosure = useDisclosure();
    const [selectedStepId, setSelectedStepId] = useState<string>('');
    const { user } = useUser();

    // Downtime
    useEffect(() => {
      getDowntime().then((response) => {
        const downtime:IDowntime = response.data;
        if (moment().isAfter(downtime.start) && moment().isBefore(downtime.end)) {
          if (user?.rank !== 'admin')
            navigate('/down');
        }

        // If downtime is in less than 20 minutes, trigger event DOWNTIME_SOON to display a warning
        const now = moment();
        const downtimeStart = moment(downtime.start);
        const diff = downtimeStart.diff(now, 'minutes');
        if (diff <= 20) {
          const event = new Event('DOWNTIME_SOON');
          window.dispatchEvent(event);
        }
      });
    }, []);

    // LOGGER
    useEffect(() => {
      const logger = Logger.getInstance();
      const url = window.location.href;
      logger.log(currentTab, LogAction.OPEN_PAGE, { url });

      // Check if cookie "tuto" exists
      if (Cookies.get('tuto')) {
        Cookies.remove('tuto');
        navigate('/tuto');
      }
    }, []);

    useEffect(() => {
      getAllModules().then((response) => {
        setModules(response.data);
        if (response.data.length > 0)
          setViewportModule(response.data[0]);
        else {
          setNoModules(true);
        }

        // Find the happy hour module
        // i.e., iterate through modules and find the first one which has a
        // happyhour_end in the future
        let happyHourModule = response.data.find((module) => {
          let happyHourEnd = moment(module.happyhour_end);
          let now = moment();
          // return if happy hour is still active (ie, deadline is higher or equal to today's DAY)
          return happyHourEnd.diff(now, 'days') >= 0;
        });
        if (happyHourModule) {
          setHappyHourModule(happyHourModule);
          const now = moment();
          const end = moment(happyHourModule.happyhour_end);
          setRemainingHappyHoursDays(end.diff(now, 'days'));
          setTotalHappyHoursDays(end.diff(moment(happyHourModule.published_at), 'days'));
        }
        else
          setNoHappyHour(true);
      });
    }, []);

    /**
     * Go to the lesson page of the step
     */
    const start_step = async (stepId: string) => {
      let step:IStep|undefined = undefined;
      let module:IModule|null = null;
      for (let i = 0; i < modules.length; i++) {
        module = modules[i];
        step = module.steps.find((step) => step._id === stepId);
        if (step) {
          break;
        }
      }
      if (!step)
        return;

      // If the step is already completed, confirm start
      if (step.state == StepState.COMPLETED || step.type == StepType.REWARD) {
        confirm_start_step(stepId);
        return;
      }

      setSelectedStepId(stepId);
      stepStartDisclosure.onOpen();
    }

    const toast = useToast();
    /**
     * Go to the lesson page of the step
     */
    const confirm_start_step = async (forcedStepId:string = "") => {
        let usedStepId = forcedStepId.length > 0 ? forcedStepId : selectedStepId;
        let step:IStep|undefined = undefined;
        let module:IModule|null = null;
        for (let i = 0; i < modules.length; i++) {
          module = modules[i];
          step = module.steps.find((step) => step._id === usedStepId);
          if (step) {
            break;
          }
        }
        
        if (!module)
          return;

        let next_step_index = module.steps.findIndex((step) => step._id === usedStepId) + 1;

        getDowntime().then((response) => {
          const downtime:IDowntime = response.data;
          // if downtime is in less than 15 minutes, prevent starting the step
          const now = moment();
          const downtimeStart = moment(downtime.start);
          const diff = downtimeStart.diff(now, 'minutes');
          if (diff <= 15 && moment().isBefore(downtime.end) && !step?.in_progress) {
            toast({
              title: "Maintenance en cours",
              description: "Une maintenance aura lieu dans quelques minutes, tu ne peux pas commencer une étape pour le moment.",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            if (!module)
              return;
            if (step && step.type == StepType.REWARD) {
              // Play chest sound
              const audio = new Audio(chest_sound);
              // set sound to 0.5 volume
              audio.volume = 0.2;
              audio.play();

              open_chest(step);

              // Set the step as completed, if not already completed
              // and update the local state of the steps in module.steps
              if (step.state != StepState.COMPLETED) {
                step.state = StepState.COMPLETED;
              }


              // Unlock the next step, or the first step of the next module if it is the last step of the current module
              if (next_step_index < module.steps.length) {
                if (module.steps[next_step_index].state == StepState.LOCKED)
                  module.steps[next_step_index].state = StepState.UNLOCKED;
              } else {
                if (modules.length > 1) {
                  let next_module_index = (modules.indexOf(module) + 1) % modules.length;
                  if (modules[next_module_index].steps.length > 0) {
                    if (modules[next_module_index].steps[0].state == StepState.LOCKED)
                      modules[next_module_index].steps[0].state = StepState.UNLOCKED;
                  }
                }
              }
            } else {
              // Play start sound
              const audio = new Audio(start_sound);
              audio.play();
              // Go to the lesson page of the step
              navigate('/lesson/' + module.slug + '/' + next_step_index);
            }
          }
        });
    }

    const open_chest = async (step:IStep) => {
      // If already completed, do nothing
      if (step.state == StepState.COMPLETED)
        return;
      setCurrentChest(step);
      chestDisclosure.onOpen();
      openChest(step._id).then(() => {
        // trigger event to update user data
        let event = new Event('UPDATE_USER_DATA');
        window.dispatchEvent(event);
      });
    }

    // Manages header content based on scroll
    const onScroll = () => {
      // Check inside which module the headerBox is
      let headerBox = document.getElementById('headerBox');
      if (headerBox) {
        let headerBoxRect = headerBox.getBoundingClientRect();
        
        // Check inside which module-<id> the headerBox is
        let module = modules.find((module) => {
          let moduleElement = document.getElementById('module-'+module._id);
          if (moduleElement) {
            let moduleRect = moduleElement.getBoundingClientRect();

            // Set the current module color to the index of the module modulo the number of colors
            setCurrentModuleColor(MODULES_COLORS[modules.indexOf(module) % MODULES_COLORS.length]);

            return (headerBoxRect.top >= moduleRect.top - 350 && headerBoxRect.bottom <= moduleRect.bottom);
          }
          return false;
        });
        if (module) {
          setViewportModule(module);
        }
      }
    }

    useEffect(() => {
      if (modules.length > 0) {
        // Add listener on scroll
        if (!listenerAdded) {
          window.addEventListener('scroll', onScroll);
          setListenerAdded(true);
        }
      }
    }, [modules]);
  
    return (
      <Box minH="100vh" bg={"#131f24"}>
        {process.env.REACT_APP_DEBUG === "true" &&
          <Box width={'100%'} pt={2} fontWeight={800} color={'white'} bgColor={'red.500'}><Center>Local</Center></Box>
        }
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />

        <ChestModal isOpen={chestDisclosure.isOpen} onClose={chestDisclosure.onClose} step={currentChest} />
        <StepStartModal isOpen={stepStartDisclosure.isOpen} onClose={stepStartDisclosure.onClose} onStart={() => {confirm_start_step()}} />

        <Flex width={'100%'}>

          {/* Left spacing for centering */}
          <Box width={'5%'} />

          {/* MAIN PATH COLUMN */}
          <Box ml={{ base: 0, md: 60 }} p="4" color={"white"} width={["100%", "100%", '50%']}>
            {!noModules &&
              <Center position={"sticky"} top={"15px"} zIndex={100} mb={5}>
                  <Box id='headerBox' rounded={"2xl"} bgColor={currentModuleColor} color={"white"} p={5} width={"700px"}>
                      <Center>
                          {viewportModule && viewportModule.title.length > 0 &&
                            <Text cursor={'default'} fontWeight={900} fontSize={"2xl"}>
                                {viewportModule ? viewportModule.title : "Chargement..."}
                            </Text>
                          }
                          {viewportModule && viewportModule.title.length == 0 &&
                            <Text cursor={'default'} fontWeight={900} fontSize={"2xl"}>
                                Module sans nom
                            </Text>
                          }
                      </Center>
                  </Box>
              </Center>
            }
            {noModules &&
              <AbsoluteCenter >
                <Text fontWeight={700} fontSize={'xl'}>
                  Il n'y a pas encore de modules disponibles pour ce cours :(
                </Text>
              </AbsoluteCenter>
            }

            {modules.map((module, moduleIndex) => {
              return (<Box key={module._id} id={"module-"+module._id} position={'relative'}>

                {moduleIndex == 0 &&
                  <Character 
                    name={'Geraldine'} 
                    action={2}
                    position={'absolute'}
                    top={'53%'}
                    left={['10%','10%','10%','10%', '22%']}
                  />
                }

                {moduleIndex == 0 &&
                  <Character 
                    name={'Geraldine'}
                    action={Math.random() < 0.5 ? 1 : 3}
                    position={'absolute'}
                    top={'10%'}
                    left={'58%'}
                    transform={'scaleX(-1)'}
                  />
                }

                {moduleIndex == 1 &&
                  <Character 
                    name={'Estelle'} 
                    action={3}
                    position={'absolute'}
                    top={'25%'}
                    left={'55%'}
                    transform={'scaleX(-1)'}
                    // filter={'grayscale(80%) hue-rotate(200deg) brightness(0.6)'}
                    height={'220px'}
                  />
                }

                {moduleIndex == 1 &&
                  <Character 
                    name={'Estelle'} 
                    action={2}
                    position={'absolute'}
                    top={'64%'}
                    left={['10%','10%','10%','10%', '22%']}
                    // filter={'grayscale(80%) hue-rotate(200deg) brightness(0.6)'}
                  />
                }

                 {moduleIndex == 2 &&
                  <Character 
                    name={'Benoit'} 
                    action={2}
                    position={'absolute'}
                    top={'70%'}
                    left={'10%'}
                    // filter={'grayscale(80%) hue-rotate(200deg) brightness(0.6)'}
                  />
                }

                {moduleIndex == 2 &&
                  <Character 
                    name={'Benoit'} 
                    action={3}
                    position={'absolute'}
                    top={['25%']}
                    left={'65%'}
                    transform={'scaleX(-1)'}
                    // filter={'grayscale(80%) hue-rotate(200deg) brightness(0.6)'}
                  />
                }

                {moduleIndex == 3 &&
                  <Character 
                    name={'Alyssia'} 
                    action={Math.random() < 0.5 ? 1 : 3}
                    position={'absolute'}
                    top={'30%'}
                    left={'60%'}
                    transform={'scaleX(-1)'}
                    // filter={'grayscale(80%) hue-rotate(200deg) brightness(0.6)'}
                    // height={'120px'}
                  />
                }

                {moduleIndex == 3 &&
                  <Character 
                    name={'Alyssia'} 
                    action={2}
                    position={'absolute'}
                    top={'75%'}
                    left={['5%','5%','5%','5%','10%']}
                    // filter={'grayscale(80%) hue-rotate(200deg) brightness(0.6)'}
                  />
                }

{/* 
                {moduleIndex == 4 &&
                  <Character 
                    name={'Benoit'} 
                    action={2}
                    position={'absolute'}
                    top={'20%'}
                    left={'55%'}
                    transform={'scaleX(-1)'}
                    filter={'grayscale(80%) hue-rotate(200deg) brightness(0.6)'}
                    // height={'100px'}
                  />
                }

                {moduleIndex == 4 &&
                  <Character 
                    name={'Benoit'} 
                    action={1}
                    position={'absolute'}
                    top={'59%'}
                    left={'30%'}
                    filter={'grayscale(80%) hue-rotate(200deg) brightness(0.6)'}
                  />
                } */}

                {moduleIndex == 4 &&
                  <Character 
                    name={'Geraldine'} 
                    action={2}
                    position={'absolute'}
                    top={'53%'}
                    left={['10%','10%','10%','10%', '22%']}
                  />
                }

                {moduleIndex == 4 &&
                  <Character 
                    name={'Geraldine'}
                    action={Math.random() < 0.5 ? 1 : 3}
                    position={'absolute'}
                    top={'10%'}
                    left={'58%'}
                    transform={'scaleX(-1)'}
                  />
                }

                {moduleIndex == 5 &&
                  <Character 
                    name={'Group'} 
                    action={2}
                    position={'absolute'}
                    top={'55%'}
                    height="300px"
                  />
                }
                
                {moduleIndex > 0 &&
                  <Box mb={20} mt={20} display={"flex"} alignItems={"center"} justifyContent={"space-around"} textAlign={"center"}>
                    <Box flexGrow={1} height={"2px"} bgColor={"#4e5f68"} margin={"0 10px"} />
                    <Text cursor={"default"} fontWeight={"bold"} padding={"0 10px"} whiteSpace={"nowrap"} color={"#4e5f68"}>{module.title}</Text>
                    <Box flexGrow={1} height={"2px"} bgColor={"#4e5f68"} margin={"0 10px"}/>
                  </Box>
                }

                <Center>
                  {/* Steps */}
                  <Box width={"100%"} border={"2pt dashed rgba(255, 0, 0, 0)"}>
                    {(module.steps).map((step, index) => {
                        return (<Box key={step._id + index}>
                        <Center position={"relative"}>
                            <StepTile moduleIndex={moduleIndex} hasFlag={index == 4} start_callback={start_step} step={step} index={index} />
                        </Center>
    
                        <Box height={"15px"} />
                        </Box>);
                    })}
                  </Box>
                </Center>
              </Box>);

            })}
          </Box>

          {/* Quests column */}
          
          <Hide below='md'>
            <Box height={'fit-content'} width={['25%']} position={'sticky'} top={'15px'}>
              {!noHappyHour &&
                <Box p={5} m={4} rounded={'xl'} border={'3px solid #37464F'} color={'white'} bgColor={'#121F24'}>
                  <HStack>
                    <Image draggable={false} src={'https://d35aaqx5ub95lt.cloudfront.net/images/icons/47112600732328e46768927036578c8b.svg'} width={['0px','0px','0px', '40px']} />
                    <Text fontSize={['sm', 'sm', 'sm', 'xl']} mt={2} color={'#FF9601'} fontWeight={850} cursor={'default'}>HAPPY HOURS !</Text>
                  </HStack>

                  <Center mt={3} mb={2} textAlign={'center'}>
                    {happyHourModule &&
                      <Text color={'white'} fontWeight={800} fontSize={['sm', 'sm', 'sm', 'xl']} cursor={'default'}>{happyHourModule!.title.length > 0 ? happyHourModule.title : 'Module sans nom'}</Text>
                    }
                    {!happyHourModule &&
                      <Skeleton height={'20px'} width={'210px'} />
                    }
                  </Center>

                  <Center>
                    <Box ms={2} me={2} mb={3} width={'100%'} height={"20px"} bgColor={"#37464F"} rounded={'xl'} position="relative">
                      <Box 
                        width={(remainingHappyHoursDays/totalHappyHoursDays)*100+'%'}
                        height={"20px"} 
                        bgColor={"#FFC700"} 
                        rounded={'xl'} 
                        position="absolute" 
                        top={0} 
                        left={0}
                        zIndex={1}
                      />
                      <Center 
                        position="absolute" 
                        top={0} 
                        left={0} 
                        width={'100%'} 
                        height={"20px"} 
                        zIndex={2}
                      >
                        {remainingHappyHoursDays > 1 &&
                          <Text cursor={'default'} fontSize={['7pt', '7pt', '7pt', 'xs']} fontWeight={800} color={'#FFFFFF'}>{remainingHappyHoursDays} jours restants</Text>
                        }
                        {remainingHappyHoursDays == 1 &&
                          <Text cursor={'default'} fontSize={['7pt', '7pt', '7pt', 'xs']} fontWeight={800} color={'#FFFFFF'}>{remainingHappyHoursDays} jour restant</Text>
                        }
                        {remainingHappyHoursDays == 0 &&
                          <Text className='slow-up-and-down' cursor={'default'} fontSize={['7pt', '7pt', '7pt', 'xs']} fontWeight={800} color={'#FFFFFF'}>Dernier jour !</Text>
                        }
                      </Center>
                    </Box>
                  </Center>

                    {!happyHourModule &&
                      <SkeletonText mt={4} noOfLines={3} spacing={4} startColor={'#52656D'} endColor={'#515557'} />
                    }
                    {happyHourModule &&
                    <Text fontSize={['xs', 'xs', 'xs', 'sm']} color={'#52656D'} cursor={'default'} fontWeight={600}>Remporte 2x plus de diamants sur les étapes de ce module jusqu'au <span style={{fontWeight:800}}>{
                      happyHourModule ? new Date(happyHourModule.happyhour_end).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' }) : "Chargement..."
                      }</span> !</Text>
                    }
                </Box>
              }

              <Box p={5} m={4} rounded={'xl'} border={'3px solid #37464F'} color={'white'}>
                <HStack>
                  <Image draggable={false} src={target_img} width={['0px','0px','0px', '40px']} />
                  <Text fontSize={'xl'} fontWeight={800} cursor={'default'}>Quêtes</Text>
                </HStack>

                <Center mt={3} mb={2}>
                  <Text fontSize={'sm'} color={'#52656D'} fontWeight={600} cursor={'default'}>Aucune quête en cours</Text>
                </Center>
              </Box>
            </Box>
          </Hide>
          
        </Flex>

      <Box height={'200px'} />
            
      </Box>
    );
  }
  export default ProgressTracker;